import {BlockDisconnectComponent} from './../block-disconnect/block-disconnect.component';
import {ComponentGeneratorService} from './component-generator.service';
import {ComponentRef, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorPopupService {

    private component;
    private blockUiComponent: ComponentRef<BlockDisconnectComponent>;

    constructor(
        private componentGeneratorService: ComponentGeneratorService
    ) {
    }


    async initErrorPopup(title: string, info: string): Promise<void> {

        if (this.blockUiComponent) {
            this.updateErrorPopup({title, info});
            return;
        }

        this.component = await this.componentGeneratorService.create(BlockDisconnectComponent, 'body');
        this.blockUiComponent = (<ComponentRef<BlockDisconnectComponent>>this.component);

        this.blockUiComponent.instance.title = title;
        this.blockUiComponent.instance.info = info;
    }

    private updateErrorPopup(data: { title: string, info: string }): void {
        this.blockUiComponent.instance.title = data.title ? data.title : this.blockUiComponent.instance.title;
        this.blockUiComponent.instance.info = data.info ? data.info : this.blockUiComponent.instance.info;
    }


    killErrorPopup(): void {
        if (this.blockUiComponent) {
            this.blockUiComponent.destroy();
            this.blockUiComponent = null;
        }

    }
}
